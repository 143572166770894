.LayoutFooterCopyright {
  text-align: center;
  height: 50px;
  margin-top: 4rem;
}
.LayoutFooterCopyright span {
  display: block;
  color: rgba(45, 54, 105, 0.5);
  margin: 10px 10px 0 0;
  padding: 5px 10px;
}
