.SiteHeader {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(36, 50, 66, 0.15);
  color: inherit;
}

.SiteHeaderLogoWrapper {
  box-sizing: border-box;
  flex: 0 1 auto;
  position: relative;
}
.SiteHeaderLogOut {
  box-sizing: border-box;
  flex: 1 1 auto;
  position: relative;
  text-align: right;
  color: #212850;
}

.SiteHeaderRow {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  align-items: center;
  height: 80px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

.BreadCrumb {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.BreadCrumbBar {
  background-color: #01a384;
  height: 5px;
  width: 20%;
  transition: width 0.3s ease;
}

.Logo {
  max-width: 73%;
  height: auto;
}

.SiteHeaderLogOut button {
  display: block;
  border: 0;
  background-color: transparent;
  color: #01a384;
  font-size: 17px;
  cursor: pointer;
  float: right;
}
.SiteHeaderLogOut:hover button {
  color: #00a385;
  cursor: pointer;
  text-decoration: underline;
}
.LinkButton:focus button {
  outline: 0 !important;
}

@media only screen and (max-width: 990px) {
  /* center de logo to center */
  .SiteHeaderLogoWrapper {
    box-sizing: border-box;
    flex: 1 1 auto;
    position: relative;
  }

  .Logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 40%;
    height: auto;
  }
  .SiteHeaderLogOut {
    text-align: center;
  }
}
