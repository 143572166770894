body {
  margin: 0;
  color: #2d3669 !important;
  background-color: #fdfdfe;
}

h1,
h2,
h3 {
  font-family: HelveticaNeueLTPro-BlkCn;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1px;
  color: #212850;
}
h1 {
  font-size: 30px;
}

p {
  font-size: 16px;
  color: #2d3669 !important;
}

h4 {
  font-family: HelveticaNeueLTPro-Md !important;
  font-weight: bolder !important;
  font-style: normal;
}

h5 {
  font-size: 18px !important;
  font-family: HelveticaNeueLTPro-Md;
  font-weight: bolder;
  font-style: normal;
}

button,
p,
span,
div {
  font-family: HelveticaNeueLTPro-Roman;
  font-weight: normal;
  font-style: normal;
}

label {
  font-family: HelveticaNeueLTPro-Md;
  font-weight: normal;
  font-style: normal;
}

.container {
  padding-right: none;
  padding-left: none;
}

.modal-body {
  padding: 1rem 3rem 3rem 3rem !important;
}

.modal-header {
  border-bottom: none;
  padding: 0.5rem 1rem;
}

.card-header {
  background-color: #fafafc;
  text-align: right;
  padding: 5px;
}

.card {
  border: none;
}

.accordion > .card > .card-header {
  color: #01a384;
}

.accordion > .card > .card-header:hover {
  color: #00a385;
  cursor: pointer;
  text-decoration: underline;
}

#hubspotIframe {
  position: relative;
  height: 640px;
  width: 100%;
  border: none;
}

.spinner-border {
  width: 4rem !important;
  height: 4rem !important;
}

@media only screen and (max-width: 990px) {
  h1 {
    margin: 2rem 0;
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.4rem !important;
  }
  .modal-body {
    padding: 1rem !important;
  }

  .col-md-2 {
    flex: none;
  }
}
